<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="blogAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Backend Search -->
              <b-col class="col-12 col-sm-8 col-md-6 col-lg-5 mb-2">
                <b-form-input class="form-control"
                  @input="searchblogWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->
              <b-col class="col-12 col-sm-8 col-md-5 col-lg-4 mb-2">
                <select v-model="blog_category" class="form-control" @change="setFilters" title="Blog category name">
                  <option :value="null" disabled>Choose Blog Category</option>
                  <option :value=null>All</option>
                  <option v-for="(category, index) of blogFiltersList['blog_category']" :key="('blog_category'+index+1)" :value="category.blog_category">
                    {{category.blog_category}}
                  </option>
                </select>
              </b-col>
              <b-col class="col-12 col-sm-4 col-md-6 col-lg-3 pr-0 mb-3">
                <div class="dateRange">
                  <date-range-picker ref="picker" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getblogDateWise()" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="blogObjList && blogObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="blogObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                  <template v-slot:cell(created_on)="data">
                    {{data.item.created_on | dateFormatMMMMDDYYYY}}
                  </template>
                  <template v-slot:cell(modified_on)="data">
                    {{data.item.modified_on | dateFormatMMMMDDYYYY}}
                  </template>

                  <template v-slot:cell(blog_title)="data">
                    {{ data.item.blog_title }} <br>
                    <small>{{ data.item.blog_id }} <i class="fa-solid fa-copy pointer" title="Copy Blog Id" @click="doCopy(data.item.blog_id)"></i></small>
                    <b-badge v-if="data.item.deleted === 0" class="pointer ml-1" variant="success mr-1 mb-1" size="sm">
                      Published & Private&nbsp;
                    </b-badge>
                    <b-badge v-if="data.item.deleted === 3" class="pointer ml-1 darkGreen" style="color: #fff;" variant="mr-1 mb-1" size="sm">
                      published & Public&nbsp;
                    </b-badge>
                    <b-badge v-if="data.item.deleted === 2" class="pointer ml-1" variant="warning mr-1 mb-1" size="sm">
                      Unpublished
                    </b-badge>
                    <b-badge v-if="data.item.deleted === 1" class="pointer ml-1" variant="primary mr-1 mb-1" size="sm">
                      Deleted
                    </b-badge><br>

                    <b-button variant="light mr-1 mb-1" size="sm" @click="blogPublishUnpublish(data.item, 2)">
                      <i class="fa fa-toggle-off m-0 font-size-20" title="Unpublish" style="color:red"></i>
                    </b-button>
                    <b-button variant="light mr-1 mb-1" size="sm" @click="blogPublishUnpublish(data.item, 0)">
                      <i class="fa fa-toggle-on m-0 font-size-20 " title="Published & Private " style="color:var(--iq-success) "></i>
                    </b-button>
                    <b-button variant="light mr-1 mb-1" size="sm" @click="blogPublishUnpublish(data.item, 3)">
                      <i class="fa fa-bullhorn m-0 font-size-20" title="Published & Public" style="color:green"></i>
                    </b-button><br>
                    <b-button class="font-size-20" variant="mr-1 mb-1" size="sm" @click="blogEdit(data.item)">
                      <i class="ri-ball-pen-fill m-0"></i>
                    </b-button>
                    <b-button class="font-size-20" variant="iq-bg-danger" @click="showblogDeleteDialog(data.item)" size="sm">
                      <i class="ri-delete-bin-line m-0"></i>
                    </b-button>
                    <b-button class="font-size-20" variant="iq-bg-danger"  @click="showblogViewDialog(data.item)" size="sm">
                      <i title="Blog Preview" class="fa fa-eye m-0"></i>
                    </b-button>
                    <b-button class="font-size-20" variant="iq-bg-danger" @click="showblogView(data.item, 'private')" size="sm">
                      <i title="Published & Private Blog" class="fa-brands fa-edge m-0"></i>
                    </b-button>
                    <b-button class="font-size-20" variant="iq-bg-danger" @click="showblogView(data.item, 'public')" size="sm">
                      <i title="Published & Public Blog" class="fa-brands fa-chrome m-0"></i>
                    </b-button>
                  </template>
                  <template v-slot:cell(blog_desc)="data">
                    <div v-html="data.item.blog_desc" class="fourLineOnly desc_img"></div>
                  </template>
                  <template v-slot:cell(blog_video)="data">
                    <iframe :src="data.item.blog_video" style="width: 100%; height: auto;" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </template>
                  <template v-slot:cell(blog_img)="data">
                    <img :src="data.item.blog_img" style="height:100px" class="fourLineOnly"/>
                  </template>
                  <template v-slot:cell(blog_keywords)="data">
                    <div v-html="data.item.blog_keywords" class="fourLineOnly"></div>
                  </template>
                  <template v-slot:cell(blog_short_desc)="data">
                    <div v-html="data.item.blog_short_desc" class="fourLineOnly"></div>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-col sm="12" md="12" class="my-1">
              <b-row>
                <b-col sm="12" md="7">
                  <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                </b-col>
                <b-col sm="5" md="5">
                  <b-pagination
                    v-model="currentBatchNo"
                    :total-rows="totalRows"
                    :per-page="dataPerPage"
                    align="fill"
                    size="sm"
                    class="my-0"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <!-- Gide BLog Add -->
    <b-modal
      v-model="showModelblogAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xxl"
    >
      <blogAdd :propOpenedInModal="true" @emitCloseblogAddModal="closeblogAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="blogAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeblogAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal><!-- Gide BLog Add -->

    <!-- Gide BLog Edit -->
    <b-modal
      v-model="showModelblogEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xxl"
    >
      <blogEdit :propOpenedInModal="true" :propblogObj="blogEditObj" @emitCloseblogEditModal="closeblogEditModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeblogEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal><!-- Gide BLog Edit -->

    <!-- Gide BLog View -->
    <b-modal
      v-model="showModelblogView"
      scrollable
      :title="cvViewModalHeader"
      size="xxl"
    >
      <gideBlogView :propOpenedInModal="true" :propBlogVueObj="blogViewObj" @emitCloseblogViewModal="closeblogViewModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeblogViewModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal><!-- Gide BLog View -->

    <!-- Gide BLog Delete -->
    <b-modal v-model="showModelblogDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelblogDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="blogDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal><!-- Gide BLog Delete -->

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { blogs } from "../../../FackApi/api/blog.js"
import blogAdd from "./blogAdd.vue"
import blogEdit from "./blogEdit.vue"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"
import gideBlogView from "../../Public/GideBlogView.vue"

export default {
  name: "blogList",
  components: {
    blogAdd,
    blogEdit,
    gideBlogView
  },
  data () {
    return {
      cvCardTitle: "Blogs",
      cvbtnAddNew: "Add",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Blog",
      cvAddModalHeader: "Add Blog",
      cvbtnModalCancel: "Close",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Blog",
      showModelblogAdd: false,
      showModelblogEdit: false,
      showModelblogView: false,
      showModelblogDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left", sortable: true },
        { label: "Title", key: "blog_title", class: "text-left w-300px", sortable: true },
        { label: "Short Description", key: "blog_short_desc", class: "text-left", sortable: true },
        { label: "Keywords", key: "blog_keywords", class: "text-left", sortable: true },
        { label: "Blog", key: "blog_desc", class: "text-left", sortable: true },
        { label: "Category", key: "blog_category", class: "text-left", sortable: true },
        { label: "Image", key: "blog_img", class: "text-left", sortable: true },
        { label: "Blog Video", key: "blog_video", class: "text-left w-300px", sortable: true },
        { label: "Keywords", key: "blog_keywords", class: "text-left", sortable: true },
        { label: "Short Description", key: "blog_short_desc", class: "text-left", sortable: true },
        { label: "Created On", key: "created_on", class: "text-left", sortable: true },
        { label: "Published On", key: "modified_on", class: "text-left", sortable: true }
      ],
      blogObjList: [],
      blogEditObj: null,
      blogViewObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      },
      search_param: "",
      currentBatchNo: 1,
      dataPerPage: 25,
      blog_category: "",
      showLoadMoreBtn: true,
      cvViewModalHeader: "Blog view",
      blogFiltersList: []
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  async beforeMount () {
    // Auth Access for Current User Role
    if (this.userData.user_role == "USERROLE11114") {
      // We will display only the sessions of the user, It is necessary when the user has multiple sessions and he/she wants to view the session
      // DONT CARE CASE
    }
    else if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    await this.getDateFilter()
    socialvue.index()
    await this.blogFilterList("blog_category")
    this.blogList()
  },
  methods: {
    /**
     * blogPublishUnpublish
     */
    async blogPublishUnpublish (blog, status) {
      let payload = {
        blog_id: blog.blog_id,
        deleted: status
      }

      const blogResp = await blogs.blogPublishUnpublish(this, payload)
      if (blogResp && blogResp.resp_status) {
        blog.deleted = payload.deleted
      }
      else {
        ApiResponse.responseMessageDisplay(this, blogResp)
      }
    },
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
    */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * searchblogWithParams
     */
    async searchblogWithParams () {
      if (this.whereFilter.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }
      this.currentBatchNo = 1
      this.blogList()
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.blogList(true)
    },
    /**
     * Calling Function as per whereFilter
    */
    async getblogDateWise () {
      this.blogList()
      await this.setDateFilter()
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * blogList
     */
    async blogList (loadViaLoadMore = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.search_param = this.search_param
        this.whereFilter.blog_category = this.blog_category

        let blogListResp = await blogs.blogList(this, this.whereFilter)
        if (blogListResp.resp_status) {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.blogObjList = [ ...blogListResp.resp_data.data ]
          }
          else {
            this.blogObjList = [ ...this.blogObjList, ...blogListResp.resp_data.data ]
          }
          this.showLoadMoreBtn = true

          // Adding the serial numbers:
          this.blogObjList.forEach((s, index) => { s.id = index + 1 })
          this.totalRows = this.blogObjList.length
        }
        else {
          this.showLoadMoreBtn = false
          this.currentBatchNo = this.currentBatchNo - 1
          this.toastMsg = blogListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at blogList() and Exception:", err)
      }
    },
    /**
     * blogAdd
     */
    blogAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/blog_add")
        }
        else {
          this.showModelblogAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at blogAdd() and Exception:", err.message)
      }
    },
    /**
     * blogEdit
     */
    blogEdit (item) {
      try {
        this.blogEditObj = item
        this.showModelblogEdit = true
      }
      catch (err) {
        console.error("Exception occurred at blogEdit() and Exception:", err.message)
      }
    },
    /**
     * showblogDeleteDialog
     */
    showblogDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelblogDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showblogDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * showblogViewDialog
     */
    showblogViewDialog (item) {
      try {
        this.blogViewObj = item
        this.showModelblogView = true
      }
      catch (err) {
        console.error("Exception occurred at showblogViewDialog() and Exception:", err.message)
      }
    },
    /**
     * Show the public view of the blog
     */
    showblogView (item, blogVisibilityType) {
      if (blogVisibilityType == "public") {
        window.open(`${process.env.VUE_APP_SERVER_URL}/blog/${item.blog_id}`, "_blank")
      }
      else {
        window.open(`${process.env.VUE_APP_SERVER_URL}/blog_view/${item.blog_id}`, "_blank")
      }
    },
    /**
     * blogDelete
     */
    async blogDelete () {
      try {
        let blogDelResp = await blogs.blogDelete(this, this.delObj.blog_id)
        await ApiResponse.responseMessageDisplay(this, blogDelResp)

        if (blogDelResp && !blogDelResp) {
          this.showModelblogDelete = false
          return false
        }

        let index = this.blogObjList.indexOf(this.delObj)
        this.blogObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelblogDelete = false
      }
      catch (err) {
        console.error("Exception occurred at blogDelete() and Exception:", err.message)
      }
    },
    /**
     * closeblogAddModal
     */
    closeblogAddModal (blogAddData) {
      try {
        if (blogAddData) {
          if (this.blogObjList && this.blogObjList.length >= 1) {
            let blogObjLength = this.blogObjList.length
            let lastId = this.blogObjList[blogObjLength - 1]["id"]
            blogAddData.id = lastId + 1
          }
          else {
            this.blogObjList = []
            blogAddData.id = 11111
          }

          blogAddData.created_on = moment()
          this.blogObjList.unshift(blogAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelblogAdd = false
        this.blogList()
      }
      catch (err) {
        console.error("Exception occurred at closeblogAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeblogEditModal
     */
    closeblogEditModal () {
      try {
        this.showModelblogEdit = false
        this.blogList()
      }
      catch (err) {
        console.error("Exception occurred at closeblogEditModal() and Exception:", err.message)
      }
    },
    /**
     * closeblogViewModal
     */
    closeblogViewModal () {
      try {
        this.showModelblogView = false
      }
      catch (err) {
        console.error("Exception occurred at closeblogViewModal() and Exception:", err.message)
      }
    },
    /**
     * openUrlInNewTab
     */
    openUrlInNewTab (url) {
      window.open(url, "_blank")
    },
    /*
     * blogFilterList
     */
    async blogFilterList (filterName = null) {
      if (!filterName) {
        return
      }
      try {
        let blogFilterListResp = await blogs.blogFilterList(this, filterName)
        if (blogFilterListResp) {
          this.blogFiltersList[filterName] = blogFilterListResp.resp_data
        }
      }
      catch (err) {
        console.error("Exception occurred at blog_filter_list_resp() and Exception:", err.message)
      }
    },
    /**
     * setFilters
    */
    async setFilters () {
      await this.blogList()
    },
    /**
     * Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")
      document.body.appendChild(dummy)
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    }
  }
}
</script>
